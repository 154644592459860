<template>
  <Navbar></Navbar>

  <!-- Loading Indicator -->
  <section class="section" v-if="isLoading">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
  </section>

  <!-- Main Content -->
  <section class="section box container" v-else>
    <!-- Filters -->
    <div class="columns is-multiline">
      <!-- Date Range Selector -->
      <div class="column is-narrow">
        <label class="label">Date Range:</label>
        <VueMultiselect
          v-model="selectedDateRange"
          :options="dateRanges"
          label="label"
          track-by="value"
          placeholder="Select Date Range"
          class="multiselect"
        ></VueMultiselect>
      </div>

      <!-- Custom Start Date Filter -->
      <div class="column is-narrow" v-if="selectedDateRange.value === 'custom'">
        <label class="label">Start Date:</label>
        <input type="date" v-model="startDate" class="input" />
      </div>

      <!-- Custom End Date Filter -->
      <div class="column is-narrow" v-if="selectedDateRange.value === 'custom'">
        <label class="label">End Date:</label>
        <input type="date" v-model="endDate" class="input" />
      </div>

      <!-- Truck/Vessel Filter -->
      <div class="column is-half is-narrow">
        <label class="label">{{ entityLabel }} Filter:</label>
        <VueMultiselect
          v-model="selectedEntities"
          :options="allEntities"
          :multiple="true"
          :close-on-select="true"
          :placeholder="'Select ' + entityLabel"
          label="name"
          track-by="name"
          class="multiselect"
        ></VueMultiselect>
      </div>
    </div>

    <!-- Infographic Cards -->
    <div class="columns is-multiline">
      <div
        class="column is-one-quarter"
        v-for="(card, index) in kpiCards"
        :key="index"
      >
        <div class="card kpi-card">
          <div class="card-content has-text-centered">
            <p class="title is-4">{{ card.title }}</p>
            <p class="subtitle is-2">{{ formatNumber(card.value) }}</p>
            <p
              class="kpi-change"
              :class="{
                'has-text-success': card.change >= 0,
                'has-text-danger': card.change < 0,
              }"
            >
              <span class="icon" v-if="card.change !== null">
                <i
                  :class="{
                    'fas fa-arrow-up': card.change >= 0,
                    'fas fa-arrow-down': card.change < 0,
                  }"
                ></i>
              </span>
              {{ formatChange(card.change) }}
            </p>
            <p class="comparison-period">Compared to {{ comparisonLabel }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Creative Text for Carbon Savings -->
    <div class="notification is-primary has-text-centered" v-if="co2SavingText">
      {{ co2SavingText }}
    </div>

    <!-- Graphs -->
    <div class="columns is-multiline">
      <!-- Volume Over Time -->
      <div class="column is-full">
        <div class="box graph-box">
          <p class="title is-5">Volume Over Time</p>
          <LineChart
            :chart-data="volumeOverTimeData"
            :chart-options="lineChartOptions"
            :key="chartKey"
          />
        </div>
      </div>

      <!-- Top Entities by Volume -->
      <div class="column is-half">
        <div class="box graph-box">
          <p class="title is-5">Top {{ entityLabel }}s by Volume</p>
          <BarChart
            :chart-data="topEntitiesData"
            :chart-options="barChartOptions"
            :key="chartKey"
          />
        </div>
      </div>

      <!-- Transactions by Entity -->
      <!-- <div class="column is-half">
        <div class="box graph-box">
          <p class="title is-5">Transactions by {{ entityLabel }}</p>
          <PieChart
            :chart-data="transactionsByEntityData"
            :chart-options="pieChartOptions"
            :key="chartKey"
          />
        </div>
      </div> -->

      <!-- Average Volume per Transaction by Entity -->
      <div class="column is-half">
        <div class="box graph-box">
          <p class="title is-5">
            Average Volume per Transaction by {{ entityLabel }}
          </p>
          <RadarChart
            :chart-data="averageVolumeData"
            :chart-options="radarChartOptions"
            :key="chartKey"
          />
        </div>
      </div>

      <!-- Carbon Savings Over Time -->
      <div class="column is-full">
        <div class="box graph-box">
          <p class="title is-5">Carbon Savings Over Time</p>
          <LineChart
            :chart-data="co2SavingsOverTimeData"
            :chart-options="lineChartOptions"
            :key="chartKey"
          />
        </div>
      </div>
    </div>
  </section>

  <Footer></Footer>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import LineChart from "@/components/LineChart.vue";
import BarChart from "@/components/BarChart.vue";
import PieChart from "@/components/PieChart.vue";
import RadarChart from "@/components/RadarChart.vue";
import VueMultiselect from "vue-multiselect";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "AnalyticsPage",
  components: {
    Navbar,
    Footer,
    Loading,
    LineChart,
    BarChart,
    PieChart,
    RadarChart,
    VueMultiselect,
  },
  setup() {
    const store = useStore();
    const userProfile = computed(() => store.state.userProfile);

    // Loading state
    const isLoading = ref(true);

    // Filters
    const selectedDateRange = ref({
      label: "Last 6 Months",
      value: "last6months",
    });
    const dateRanges = [
      { label: "Last Week", value: "lastweek" },
      { label: "Last Month", value: "lastmonth" },
      { label: "Last 3 Months", value: "last3months" },
      { label: "Last 6 Months", value: "last6months" },
      { label: "Last Year", value: "lastyear" },
      { label: "Year-to-Date (YTD)", value: "ytd" },
      { label: "Custom", value: "custom" },
    ];

    const startDate = ref("");
    const endDate = ref("");
    const comparisonStartDate = ref("");
    const comparisonEndDate = ref("");

    // Format Date for Input fields
    const formatDateInput = (date) => {
      return date.toISOString().substr(0, 10);
    };

    // Initialize dates
    const initializeDates = () => {
      const today = new Date();
      let start, end, compStart, compEnd;

      switch (selectedDateRange.value.value) {
        case "lastweek":
          end = new Date();
          start = new Date();
          start.setDate(end.getDate() - 7);
          compEnd = new Date(start);
          compStart = new Date(compEnd);
          compStart.setDate(compEnd.getDate() - 7);
          break;
        case "lastmonth":
          end = new Date();
          start = new Date();
          start.setMonth(end.getMonth() - 1);
          compEnd = new Date(start);
          compStart = new Date(compEnd);
          compStart.setMonth(compEnd.getMonth() - 1);
          break;
        case "last3months":
          end = new Date();
          start = new Date();
          start.setMonth(end.getMonth() - 3);
          compEnd = new Date(start);
          compStart = new Date(compEnd);
          compStart.setMonth(compEnd.getMonth() - 3);
          break;
        case "last6months":
          end = new Date();
          start = new Date();
          start.setMonth(end.getMonth() - 6);
          compEnd = new Date(start);
          compStart = new Date(compEnd);
          compStart.setMonth(compEnd.getMonth() - 6);
          break;
        case "lastyear":
          end = new Date();
          start = new Date();
          start.setFullYear(end.getFullYear() - 1);
          compEnd = new Date(start);
          compStart = new Date(compEnd);
          compStart.setFullYear(compEnd.getFullYear() - 1);
          break;
        case "ytd":
          end = new Date();
          start = new Date(end.getFullYear(), 0, 1);
          const lastYear = end.getFullYear() - 1;
          compEnd = new Date(start);
          compEnd.setDate(compEnd.getDate() - 1);
          compStart = new Date(lastYear, 0, 1);
          break;
        case "custom":
          // Do nothing
          return;
      }

      startDate.value = formatDateInput(start);
      endDate.value = formatDateInput(end);
      comparisonStartDate.value = formatDateInput(compStart);
      comparisonEndDate.value = formatDateInput(compEnd);
    };

    initializeDates();

    const selectedEntities = ref([]);
    const allEntities = ref([]);

    // Data
    const transactions = ref([]);
    const previousTransactions = ref([]);

    // Charts
    const chartKey = ref(0);

    const volumeOverTimeData = ref({
      labels: [],
      datasets: [],
    });

    const topEntitiesData = ref({
      labels: [],
      datasets: [],
    });

    const transactionsByEntityData = ref({
      labels: [],
      datasets: [],
    });

    const averageVolumeData = ref({
      labels: [],
      datasets: [],
    });

    const co2SavingsOverTimeData = ref({
      labels: [],
      datasets: [],
    });

    const lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
      scales: {
        x: {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "dd MMM",
            },
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    const barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    const pieChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };

    const radarChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        r: {
          beginAtZero: true,
        },
      },
    };

    // KPI Cards
    const kpiCards = ref([
      {
        title: "Total Volume (L)",
        value: 0,
        change: null,
      },
      {
        title: "Number of Transactions",
        value: 0,
        change: null,
      },
      {
        title: "Carbon Savings (Kg CO₂)",
        value: 0,
        change: null,
      },
      {
        title: "Avg Volume per Transaction (L)",
        value: 0,
        change: null,
      },
    ]);

    // Entity Label (Asset or Vessel)
    const entityLabel = computed(() =>
      userProfile.value && userProfile.value.group ? "Vessel" : "Asset"
    );

    const comparisonLabel = ref("");

    // Creative Text for CO2 Savings
    const co2SavingText = ref("");

    // Fetch Transactions
    const fetchTransactions = async () => {
      isLoading.value = true;
      const apiUrl = store.state.apiUrl + "getCustomerTransactions";

      if (!userProfile.value || !userProfile.value.customers) {
        console.error("User profile not available.");
        isLoading.value = false;
        return;
      }

      const customer_id = userProfile.value.customers[0].customer_id;

      try {
        // Current Period Transactions
        const response = await fetch(
          `${apiUrl}/${customer_id}?start_date=${startDate.value}&end_date=${endDate.value}`
        );
        if (response.ok) {
          const result = await response.json();
          let fetchedTransactions = result[1].transactionsData;

          // Update All Entities List
          const entitiesSet = new Set();
          fetchedTransactions.forEach((transaction) => {
            entitiesSet.add(transaction.TruckNumber);
          });
          allEntities.value = Array.from(entitiesSet).map((name) => ({ name }));

          // Filter by selected entities
          if (selectedEntities.value.length) {
            fetchedTransactions = fetchedTransactions.filter((transaction) =>
              selectedEntities.value
                .map((e) => e.name)
                .includes(transaction.TruckNumber)
            );
          }

          transactions.value = fetchedTransactions;
        } else {
          console.error("Error fetching transactions");
        }

        // Previous Period Transactions (for comparisons)
        comparisonLabel.value = `${formatDate(
          new Date(comparisonStartDate.value)
        )} - ${formatDate(new Date(comparisonEndDate.value))}`;

        const prevResponse = await fetch(
          `${apiUrl}/${customer_id}?start_date=${comparisonStartDate.value}&end_date=${comparisonEndDate.value}`
        );
        if (prevResponse.ok) {
          const result = await prevResponse.json();
          let fetchedPrevTransactions = result[1].transactionsData;

          // Filter by selected entities
          if (selectedEntities.value.length) {
            fetchedPrevTransactions = fetchedPrevTransactions.filter(
              (transaction) =>
                selectedEntities.value
                  .map((e) => e.name)
                  .includes(transaction.TruckNumber)
            );
          }

          previousTransactions.value = fetchedPrevTransactions;
        } else {
          console.error("Error fetching previous transactions");
        }

        // Update KPIs and Charts
        updateKPIs();
        updateCharts();
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        isLoading.value = false;
      }
    };

    // Update KPIs
    const updateKPIs = () => {
      const totalVolume = transactions.value.reduce(
        (sum, txn) => sum + txn.Quantity,
        0
      );
      const prevTotalVolume = previousTransactions.value.reduce(
        (sum, txn) => sum + txn.Quantity,
        0
      );
      const volumeChange =
        prevTotalVolume > 0
          ? ((totalVolume - prevTotalVolume) / prevTotalVolume) * 100
          : null;

      const numTransactions = transactions.value.length;
      const prevNumTransactions = previousTransactions.value.length;
      const transactionsChange =
        prevNumTransactions > 0
          ? ((numTransactions - prevNumTransactions) / prevNumTransactions) *
            100
          : null;

      const totalCo2Savings = calculateCo2Savings(totalVolume);
      const prevTotalCo2Savings = calculateCo2Savings(prevTotalVolume);
      const co2Change =
        prevTotalCo2Savings > 0
          ? ((totalCo2Savings - prevTotalCo2Savings) / prevTotalCo2Savings) *
            100
          : null;

      const averageVolumePerTransaction =
        numTransactions > 0 ? totalVolume / numTransactions : 0;
      const prevAverageVolumePerTransaction =
        prevNumTransactions > 0 ? prevTotalVolume / prevNumTransactions : 0;
      const averageVolumeChange =
        prevAverageVolumePerTransaction > 0
          ? ((averageVolumePerTransaction - prevAverageVolumePerTransaction) /
              prevAverageVolumePerTransaction) *
            100
          : null;

      kpiCards.value = [
        {
          title: "Total Volume (L)",
          value: totalVolume,
          change: volumeChange,
        },
        {
          title: "Number of Transactions",
          value: numTransactions,
          change: transactionsChange,
        },
        {
          title: "Carbon Savings (Kg CO₂)",
          value: totalCo2Savings,
          change: co2Change,
        },
        {
          title: "Avg Volume per Transaction (L)",
          value: averageVolumePerTransaction,
          change: averageVolumeChange,
        },
      ];

      // Update Creative Text
      co2SavingText.value = generateCo2SavingText(totalCo2Savings);
    };

    // Update Charts
    const updateCharts = () => {
      // Volume Over Time
      const volumeData = {};
      transactions.value.forEach((txn) => {
        const date = new Date(txn.SaleDate);
        const dateKey = date.toISOString().substr(0, 10);
        if (!volumeData[dateKey]) volumeData[dateKey] = 0;
        volumeData[dateKey] += txn.Quantity;
      });

      volumeOverTimeData.value.labels = Object.keys(volumeData).sort();
      volumeOverTimeData.value.datasets = [
        {
          label: "Volume (L)",
          data: volumeOverTimeData.value.labels.map(
            (date) => volumeData[date] || 0
          ),
          borderColor: "#36A2EB",
          backgroundColor: "#9BD0F5",
          fill: true,
        },
      ];

      // Top Entities by Volume
      const entityVolumeMap = {};
      transactions.value.forEach((txn) => {
        const entity = txn.TruckNumber;
        if (!entityVolumeMap[entity]) entityVolumeMap[entity] = 0;
        entityVolumeMap[entity] += txn.Quantity;
      });

      const sortedEntities = Object.entries(entityVolumeMap)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10);

      topEntitiesData.value.labels = sortedEntities.map((e) => e[0]);
      topEntitiesData.value.datasets = [
        {
          label: "Total Volume (L)",
          data: sortedEntities.map((e) => e[1]),
          backgroundColor: "#36A2EB",
        },
      ];

      // Transactions by Entity
      const entityTransactionsMap = {};
      transactions.value.forEach((txn) => {
        const entity = txn.TruckNumber;
        if (!entityTransactionsMap[entity]) entityTransactionsMap[entity] = 0;
        entityTransactionsMap[entity] += 1;
      });

      transactionsByEntityData.value.labels = Object.keys(
        entityTransactionsMap
      );
      transactionsByEntityData.value.datasets = [
        {
          data: Object.values(entityTransactionsMap),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
            "#C9CBCF",
            "#7A9E9F",
            "#F7B32B",
            "#803E75",
          ],
        },
      ];

      // Average Volume per Transaction by Entity
      const entityAvgVolumeMap = {};
      Object.keys(entityTransactionsMap).forEach((entity) => {
        entityAvgVolumeMap[entity] =
          entityVolumeMap[entity] / entityTransactionsMap[entity];
      });

      averageVolumeData.value.labels = Object.keys(entityAvgVolumeMap);
      averageVolumeData.value.datasets = [
        {
          label: "Avg Volume per Transaction (L)",
          data: Object.values(entityAvgVolumeMap),
          backgroundColor: "#FF6384",
          borderColor: "#FF6384",
        },
      ];

      // Carbon Savings Over Time
      const co2Data = {};
      transactions.value.forEach((txn) => {
        const date = new Date(txn.SaleDate);
        const dateKey = date.toISOString().substr(0, 10);
        if (!co2Data[dateKey]) co2Data[dateKey] = 0;
        co2Data[dateKey] += calculateCo2Savings(txn.Quantity);
      });

      co2SavingsOverTimeData.value.labels = Object.keys(co2Data).sort();
      co2SavingsOverTimeData.value.datasets = [
        {
          label: "Carbon Savings (Kg CO₂)",
          data: co2SavingsOverTimeData.value.labels.map(
            (date) => co2Data[date] || 0
          ),
          borderColor: "#4BC0C0",
          backgroundColor: "#AFECEC",
          fill: true,
        },
      ];

      chartKey.value++;
    };

    // Calculate CO2 Savings
    const calculateCo2Savings = (volume) => {
      const co2EmissionFactor = 2.68; // Kg CO2 per liter of diesel (example value)
      return volume * co2EmissionFactor;
    };

    // Generate Creative Text for CO2 Savings
    const generateCo2SavingText = (co2Savings) => {
      const treesPerKgCo2 = 0.05; // Example: 1 tree absorbs ~20kg CO2 per year
      const treesPlanted = (co2Savings * treesPerKgCo2).toFixed(0);

      return `Your carbon savings are equivalent to planting approximately ${treesPlanted} trees! 🌳`;
    };

    // Format Change Percentage
    const formatChange = (value) => {
      if (value === null) return "N/A";
      const formattedValue = Math.abs(value).toFixed(2) + "%";
      return formattedValue;
    };

    // Format Number with Thousand Separators
    const formatNumber = (num) => {
      if (typeof num !== "number") return num;
      return num.toLocaleString();
    };

    // Format Date to dd/mm/yyyy
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    onMounted(fetchTransactions);

    watch(
      [
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
        selectedEntities,
      ],
      fetchTransactions
    );

    watch(selectedDateRange, () => {
      initializeDates();
      if (selectedDateRange.value.value !== "custom") {
        fetchTransactions();
      }
    });

    watch(
      [startDate, endDate],
      () => {
        if (selectedDateRange.value.value === "custom") {
          const start = new Date(startDate.value);
          const end = new Date(endDate.value);
          const diffDays = (end - start) / (1000 * 60 * 60 * 24);

          const compEnd = new Date(start);
          compEnd.setDate(start.getDate() - 1);
          const compStart = new Date(compEnd);
          compStart.setDate(compEnd.getDate() - diffDays);

          comparisonStartDate.value = formatDateInput(compStart);
          comparisonEndDate.value = formatDateInput(compEnd);
        }
      },
      { immediate: true }
    );

    return {
      isLoading,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
      selectedEntities,
      allEntities,
      entityLabel,
      kpiCards,
      transactions,
      volumeOverTimeData,
      topEntitiesData,
      transactionsByEntityData,
      averageVolumeData,
      co2SavingsOverTimeData,
      lineChartOptions,
      barChartOptions,
      pieChartOptions,
      radarChartOptions,
      chartKey,
      formatChange,
      formatNumber,
      formatDate,
      comparisonLabel,
      co2SavingText,
      selectedDateRange,
      dateRanges,
    };
  },
};
</script>

<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.css";

.kpi-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.kpi-card .card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kpi-change {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.kpi-change .icon {
  margin-right: 5px;
}

.has-text-success {
  color: green;
}

.has-text-danger {
  color: red;
}

.multiselect {
  max-width: 100%;
}

.comparison-period {
  font-size: 0.9rem;
  color: #777;
}

.notification {
  margin-top: 1rem;
}

.graph-box {
  height: 45vh;
}
</style>

